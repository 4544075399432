import { Icons } from "@flow/icons";
import Branding from "./plugins/Branding";
import CustomerIcon from "./plugins/CustomerIcon";
import MainLogo from "./plugins/MainLogo";
import ManualHandling from "./components/onboarding/ManualHandling";
import SimpleViewer from "./components/onboarding/SimpleViewer";
import ManualRequestInformation from "./components/onboarding/ManualRequestInformation";

import ManualBaseInformation from "./components/onboarding/ManualBaseInformation";
import QueueItem from "./components/QueueItem";
import FileTab from "./components/FileTab";
import { taskMap } from "./helpers/taskHelper";
import CaseDetails from "./components/Details/CaseDetails";
import { fileLocator } from "./helpers/fileLocator";

const queueLimits = {
  limit: 10,
  limitIncrease: 15,
  countUrl: "/flow-definitions",
};

const manualOnboardingQueues = {
  "manual-handling": {
    name: "manual-handling",
    queueFilter:
      "?status=active&flowDefinitionId=onboarding&data.flowStatus=manual-handling&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-manual-ubo-structure": {
    name: "onboarding-manual-ubo-structure",
    queueFilter:
      "?status=active&flowDefinitionId=onboarding&data.manual-ubo-structure-verification-needed=todo&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-manual-origin-of-funds": {
    name: "onboarding-manual-origin-of-funds",
    queueFilter:
      "?status=active&flowDefinitionId=onboarding&data.manual-origin-of-funds-verification-needed=todo&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-manual-risk-score": {
    name: "onboarding-manual-risk-score",
    queueFilter:
      "?status=active&flowDefinitionId=onboarding&data.manual-risk-score-verification-needed=todo&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-manual-pep-hit": {
    name: "onboarding-manual-pep-hit",
    queueFilter:
      "?status=active&flowDefinitionId=onboarding&data.manual-pep-hit-verification-needed=todo&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-manual-sanction-hit": {
    name: "onboarding-manual-sanction-hit",
    queueFilter:
      "?status=active&flowDefinitionId=onboarding&data.manual-sanction-hit-verification-needed=todo&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-manual-verification": {
    name: "onboarding-manual-verification",
    queueFilter:
      "?status=active&flowDefinitionId=onboarding&data.custom-queue-status=needs-manual-verification&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
};

const archivedOnboardingQueues = {
  "onboarding-all-archived-cases": {
    name: "onboarding-all-archived-cases",
    queueFilter: "?status=!active&flowDefinitionId=onboarding&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-completed-cases": {
    name: "onboarding-completed-cases",
    queueFilter:
      "?status=completed&flowDefinitionId=onboarding&data.flowStatus=completed&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-declined-cases": {
    name: "onboarding-declined-cases",
    queueFilter:
      "?status=completed&flowDefinitionId=onboarding&data.flowStatus=!completed&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-cases-aborted-by-case-worker": {
    name: "onboarding-cases-aborted-by-case-worker",
    queueFilter:
      "?status=archived&flowDefinitionId=onboarding&data.archivedBy.role=case-worker&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "onboarding-cases-aborted-by-user": {
    name: "onboarding-cases-aborted-by-user",
    queueFilter:
      "?status=archived&flowDefinitionId=onboarding&data.archivedBy.role=!case-worker&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
};

const paymentQueues = {
  "payment-active-cases": {
    name: "payment-active-cases",
    queueFilter: "?status=active&flowDefinitionId=all-payments&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "payment-completed-cases": {
    name: "payment-completed-cases",
    queueFilter:
      "?status=completed&flowDefinitionId=all-payments&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "payment-archived-cases": {
    name: "payment-archived-cases",
    queueFilter:
      "?status=archived&flowDefinitionId=all-payments&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
  "payment-manually-handle-unknown-status": {
    name: "payment-manually-handle-unknown-status",
    queueFilter:
      "?data.manual-transactionstatus-assessment=required&flowDefinitionId=all-payments&view=queueView",
    queueSort: "createdAt&dir=-1",
    ...queueLimits,
  },
};

const CustomComponents = (user) => {
  return {
    queue: {
      queueItem: QueueItem,
    },
    case: {
      caseSummary: {
        map: ({ data }) => {
          const companyName =
            data.raw?.bisnode.NavnAdresse.Navn || "No bisnode data";
          const contact =
            (data.kycData && data.kycData["section-confirm-contact"]) || {};
          return [
            {
              label: "Company",
              title: companyName,
              process: ["onboarding"],
            },
            {
              label: "Organization number",
              title: data.organizationId,
              process: ["onboarding"],
            },
            {
              label: "Partner name",
              title: data.partnerId,
              process: ["onboarding"],
            },
            {
              label: "Contact email",
              title: contact.contactPersonEmail || "KYC answers not completed",
              process: ["onboarding"],
            },
            {
              label: "Contact phonenumber",
              title: contact.contactPersonPhone || "KYC answers not completed",
              process: ["onboarding"],
            },
          ];
        },
      },
      tabs: [
        {
          id: "file-tab",
          title: "Files",
          component: FileTab,
          hide: (flow) => fileLocator(flow).length <= 0,
          badge: (flow) => fileLocator(flow).length,
        },
      ],
      tasks: {
        taskComponents: {
          "manual-verification": {
            component: ManualHandling,
          },
          "four-eyes-verification-of-pep-hit": {
            component: ManualHandling,
          },
          "four-eyes-verification-of-risk-score": {
            component: ManualHandling,
          },
          "four-eyes-verification-of-sanction-hit": {
            component: ManualHandling,
          },
          "manually-check-pep-hit": {
            component: ManualHandling,
          },
          "manually-check-risk-score": {
            component: ManualHandling,
          },
          "manually-check-sanction-hits": {
            component: ManualHandling,
          },
          "manually-check-ubo-structure": {
            component: ManualHandling,
          },
          "manually-verify-origin-of-funds": {
            component: ManualHandling,
          },
          "manually-request-additional-information": {
            component: ManualRequestInformation,
          },
          "upload-documents": {
            component: SimpleViewer,
          },
          "accept-customer": {
            component: ManualHandling,
          },
          "gather-base-information": {
            component: ManualBaseInformation,
          },
        },
        taskList: [
          {
            id: "my-all-user-tasks-list",
            query: "taskCategory=user-task&status=pending",
            title: "Tasks",
            map: (task) => taskMap(task, user),
          },
          {
            id: "my-all-message-tasks-list",
            title: "Optional tasks",
            query: "taskCategory=message-task&status=pending",
          },
        ],
      },
      caseDetails: CaseDetails,
    },
    menu: {
      menuStructure: [
        { name: "my-cases" },
        { name: "onboarding-all-cases" },
        {
          name: "onboarding-manual-cases",
          icon: Icons.ManualWork,
          elems: Object.keys(manualOnboardingQueues),
        },
        {
          name: "onboarding-archived",
          icon: Icons.UserClipboard,
          elems: Object.keys(archivedOnboardingQueues),
        },
        {
          name: "payment",
          icon: Icons.Withdrawal,
          elems: Object.keys(paymentQueues),
        },
      ],
    },
    queueDefinition: {
      "my-cases": {
        name: "my-cases",
        queueType: "assignee",
        queueFilter: "?status=active",
        queueSort: "sort=createdAt&dir=-1",
        limit: 15,
      },
      "onboarding-all-cases": {
        name: "onboarding-all-cases",
        queueFilter:
          "?status=active&flowDefinitionId=onboarding&view=queueView",
        queueSort: "createdAt&dir=-1",
        ...queueLimits,
      },
      ...manualOnboardingQueues,
      ...archivedOnboardingQueues,
      ...paymentQueues,
    },
  };
};

const StartableProcesses = ["onboarding"];
const SearchFields = [];

export {
  Branding,
  CustomerIcon,
  MainLogo,
  CustomComponents,
  SearchFields,
  StartableProcesses,
};
