import React from "react";
import ReactDOM from "react-dom";

import { CaseManager, APPS, FEATURES } from "@flow/case-manager";
import * as customerSetup from "./customer";
import * as serviceWorker from "./serviceWorker";
import translatesInbox from "./translate/inbox";

import "./index.css";

import "core-js/stable";
import "regenerator-runtime/runtime";

const skin = {
  branding: customerSetup.Branding,
  customerIcon: customerSetup.CustomerIcon,
  mainLogo: customerSetup.MainLogo,
};

const customTranslates = [
  {
    app: APPS.Inbox,
    language: "en",
    translates: translatesInbox,
  },
];

const FlowPortalWithApps = (
  <CaseManager
    configuration={(user) => {
      return {
        skin,
        apps: [APPS.Inbox, APPS.Process, APPS.Search],
        customComponents: customerSetup.CustomComponents(user),
        inboxConfiguration: {
          queuesPollingEnabled: false,
          searchFields: customerSetup.SearchFields,
          startableProcesses: customerSetup.StartableProcesses,
          features: [FEATURES.CaseAssignment],
        },
        searchConfiguration: {
          searchProcesses: ["onboarding"],
          searchFields: [
            {
              name: "Company",
              value: "data.raw.bisnode.NavnAdresse.Navn",
              queryOperator: "contains",
            },
            {
              name: "Partner",
              value: "data.partnerId",
              queryOperator: "contains",
            },
            {
              name: "Organization ID",
              value: "data.organizationId",
              queryOperator: "contains",
            },
            {
              name: "Flow ID",
              value: "flowId",
            },
          ],
          searchResultFields: [
            {
              name: "Company name",
              value: "data.raw.bisnode.NavnAdresse.Navn",
              process: ["onboarding"],
            },
            {
              name: "Partner",
              value: "data.partnerId",
              process: ["onboarding"],
            },
            {
              name: "Organization ID",
              value: "data.organizationId",
              process: ["onboarding"],
            },
            {
              name: "Established date",
              value: "data.raw.bisnode.Grunnfakta.RegistrertDato",
              process: ["onboarding"],
            },
          ],
        },
        translates: customTranslates,
        language: "en",
      };
    }}
  />
);

ReactDOM.render(FlowPortalWithApps, document.getElementById("root"));
serviceWorker.unregister();
